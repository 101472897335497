var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mode === 'live')?_c('v-btn',{attrs:{"color":"white error--text","x-small":_vm.forBracket},on:{"click":function($event){$event.stopPropagation();return _vm.watch(_vm.streams[0])}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-signal-stream")]),_vm._v(" Now Streaming ")],1):(_vm.mode === 'dialog')?_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.status && !_vm.complete && _vm.streams.length)?_c('v-btn',_vm._g({attrs:{"color":"white error--text","small":!_vm.forBracket,"x-small":_vm.forBracket}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-signal-stream")]),_vm._v(" Now Streaming ")],1):(_vm.complete && _vm.streams.length)?_c('v-btn',_vm._g({attrs:{"text":!_vm.forBracket,"color":"color3 color3Text--text","small":!_vm.forBracket,"x-small":_vm.forBracket}},on),[_vm._v(" watch replay"),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fas fa-tv")])],1):(!_vm.forBracket)?_c('v-btn',_vm._g({attrs:{"fab":"","color":"color3 color3Text--text","small":!_vm.forBracket,"x-small":_vm.forBracket}},on),[_c('v-icon',{attrs:{"color":"grey lighten-3"}},[_vm._v("fas fa-video")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"color1Text"}},[_vm._v("fas fa-video")]),_vm._v(" Live Streams - Match "+_vm._s(_vm.matchNumber)+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","color":"color2 color2Text--text"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-data-table',{attrs:{"headers":[
              { text: 'Started', value: 'dtModified'},
              { text: 'Added By', value: 'userModified'},
              { text: '', value: 'action', align: 'right'}
            ],"items":_vm.streams,"dense":"","hide-default-footer":"","hide-default-header":_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"item.dtModified",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToLocalDt")(item.dtModified))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"color3","text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.watch(item)}}},[_vm._v(" watch "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("fas fa-tv")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No one "+_vm._s(_vm.complete ? 'streamed' : 'is streaming')+" this match :-( ")]},proxy:true}],null,true)})],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-alert',{attrs:{"type":"warning","value":true,"prominent":"","text":""}},[_c('div',{staticClass:"black--text"},[_c('b',[_vm._v("Using Facebook?")]),_vm._v(" Make sure your video is set to public. ")])]),_c('v-text-field',{attrs:{"label":"Live Stream URL","hint":"Copy and paste the full URL to the stream","persistent-hint":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1)],1),_c('v-card-actions',[_c('v-fab-transition',[(_vm.window > 0)?_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":"","small":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.window = 0}}},[_c('v-icon',[_vm._v("fas fa-caret-left")])],1):_vm._e()],1),_c('v-spacer'),_c('v-fab-transition',[(_vm.view.adminAndRefs || (_vm.userAdd && !_vm.complete))?_c('v-btn',{key:_vm.rightFab.key,attrs:{"color":_vm.rightFab.color,"fab":"","small":"","disabled":_vm.rightFab.disabled,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.rightFab.click($event)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.rightFab.icon))])],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-btn
    v-if="mode === 'live'"
    color="white error--text"
    :x-small="forBracket"
    @click.stop="watch(streams[0])"
  >
    <v-icon class="mr-2" small>fas fa-signal-stream</v-icon> Now Streaming
  </v-btn>
  <v-dialog
    v-else-if="mode === 'dialog'"
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="white error--text"
        v-on="on"
        v-if="status && !complete && streams.length"
        :small="!forBracket"
        :x-small="forBracket"
      >
        <v-icon class="mr-2" small>fas fa-signal-stream</v-icon> Now Streaming
      </v-btn>
      <v-btn
        v-else-if="complete && streams.length"
        :text="!forBracket"
        color="color3 color3Text--text"
        v-on="on"
        :small="!forBracket"
        :x-small="forBracket"
      >
        watch replay<v-icon class="ml-2" small>fas fa-tv</v-icon>
      </v-btn>
      <v-btn
        v-else-if="!forBracket"
        fab
        color="color3 color3Text--text"
        v-on="on"
        :small="!forBracket"
        :x-small="forBracket"
      >
        <v-icon color="grey lighten-3">fas fa-video</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>
          <v-icon color="color1Text" class="mr-3">fas fa-video</v-icon>
          Live Streams - Match {{matchNumber}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small fab @click.stop="dialog = false" color="color2 color2Text--text"><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-window v-model="window" touchless>

          <v-window-item :value="0">
            <v-data-table
              :headers="[
                { text: 'Started', value: 'dtModified'},
                { text: 'Added By', value: 'userModified'},
                { text: '', value: 'action', align: 'right'}
              ]"
              :items="streams"
              dense
              hide-default-footer
              :hide-default-header="$vuetify.breakpoint.xsOnly"
            >
              <template v-slot:[`item.dtModified`]="{item}">
                {{item.dtModified | unixToLocalDt}}
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-btn
                  color="color3"
                  text small
                  @click.stop="watch(item)"
                >
                  watch
                  <v-icon class="ml-2">fas fa-tv</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                No one {{complete ? 'streamed' : 'is streaming'}} this match :-(
              </template>
            </v-data-table>
          </v-window-item>

          <v-window-item :value="1">
            <v-alert type="warning" :value="true" prominent text>
              <div class="black--text">
                <b>Using Facebook?</b> Make sure your video is set to public.
              </div>
            </v-alert>
            <v-text-field
              label="Live Stream URL"
              v-model="url"
              hint="Copy and paste the full URL to the stream"
              persistent-hint
            ></v-text-field>
          </v-window-item>

        </v-window>

      </v-card-text>
      <v-card-actions>
        <v-fab-transition>
          <v-btn
            v-if="window > 0"
            color="color3Text color3--text"
            fab small
            @click.stop="window = 0"
            :disabled="loading"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            v-if="view.adminAndRefs || (userAdd && !complete)"
            :key="rightFab.key"
            :color="rightFab.color"
            fab small
            @click.stop="rightFab.click"
            :disabled="rightFab.disabled"
            :loading="loading"
          >
            <v-icon>{{rightFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { api } from '@/classes/_URL'

export default {
  props: ['matchNumber', 'poolId', 'bracketId', 'complete', 'forBracket', 'status'],
  data () {
    return {
      dialog: false,
      url: null,
      window: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'view']),
    userAdd () {
      return this.tournament.props.includes('streaming-users') && this.user
    },
    mode () {
      if (this.view.public && this.streams && this.streams.length === 1 && !this.complete && !this.userAdd) {
        return 'live'
      } else if ((this.view.public && this.streams && this.streams.length) || this.userAdd || this.view.adminAndRefs) {
        return 'dialog'
      }
      return null
    },
    streams () {
      return this.tournament &&
        this.tournament.streams &&
        this.tournament.streams.filter(f => !f.deleted && f.matchId === this.matchNumber && ((f.poolId && f.poolId === this.poolId) || (f.bracketId && f.bracketId === this.bracketId)))
    },
    validUrl () {
      return this.url && (this.url.startsWith('http://') || this.url.startsWith('https://'))
    },
    rightFab () {
      return this.window === 0 ? {
        icon: 'fas fa-plus',
        color: 'color3 color3Text--text',
        key: 'next',
        click: () => { this.window = 1 }
      } : {
        icon: 'fas fa-save',
        color: 'success white--text',
        disabled: !this.validUrl,
        key: 'save',
        click: this.postStream
      }
    },
    dto () {
      return {
        url: this.url,
        matchId: this.matchNumber,
        bracketId: this.bracketId,
        poolId: this.poolId
      }
    }
  },
  methods: {
    reset () {
      this.url = null
      this.window = 0
      if (this.view.public && this.streams && this.streams.length === 1 && !this.userAdd && this.dialog) {
        this.watch(this.streams[0])
      }
    },
    postStream () {
      if (!this.validUrl) return
      this.loading = true
      this.$VBL.tournament.streams.post(this.tournament.id, this.dto)
        .then(r => {
          this.tournament.update({ streams: [r.data] })
          this.reset()
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    },
    watch (item) {
      this.loading = true
      this.$http.get(`${api}/livestream/watch/${item.id}`)
        .then(r => {
          window.open(r.data, '_blank')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    dialog: 'reset'
  }
}
</script>
